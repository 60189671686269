import { faComment, faHeart, faDonate } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import ClaimChannel from "../dialogs/claim-channel"
import IdentityEditor from "../identity-editor"
import { DonationAddressForm } from "../settings/donation-address-form"
import { navigate } from "@reach/router"
import { useUser } from "../../hooks/use-user"

const Help: FunctionComponent = () => {
  const { setContent } = useModal()
  const { openLoginPrompt } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const gotoPage = (event: any, path: string) => {
    if (event) event.preventDefault()
    navigate(path)
  }

  return (
    <div>
      <h3>Help</h3>
      <p>
        <b>How to:</b>
      </p>
      <ul>
        <li>
          <a
            href="https://smartlike.org/docs/how-to-support-your-favorite-creators"
            onClick={e => {
              gotoPage(e, "how-to-support-your-favorite-creators")
            }}
          >
            support your favorite creators
          </a>
        </li>
        <li>
          <a
            href="https://smartlike.org/docs/how-to-set-up-content-monetization"
            onClick={e => {
              gotoPage(e, "how-to-set-up-content-monetization")
            }}
          >
            set up content monetization
          </a>
        </li>
        <li>
          <a
            href="https://smartlike.org/docs/resolve-issues"
            onClick={e => {
              gotoPage(e, "resolve-issues")
            }}
          >
            resolve issues
          </a>
        </li>
        <li>
          <a href="https://docs.smartlike.org/embed.html">embed smartlike</a>
        </li>
      </ul>
      <p>
        <br />
      </p>

      <p>
        <b>How it works:</b>
      </p>
      <ul>
        <li>
          <a
            href="https://smartlike.org/docs/about"
            onClick={e => {
              gotoPage(e, "about")
            }}
          >
            about
          </a>
        </li>
        <li>
          <a
            href="https://smartlike.org/docs/how-it-works"
            onClick={e => {
              gotoPage(e, "how-it-works")
            }}
          >
            overview
          </a>
        </li>
        <li>
          <a
            href="https://smartlike.org/docs/censorship-and-moderation"
            onClick={e => {
              gotoPage(e, "censorship-and-moderation")
            }}
          >
            decentralized crowdsourced moderation
          </a>
        </li>
        <li>
          <a
            href="https://smartlike.org/docs/platform-independent-creator-channels"
            onClick={e => {
              gotoPage(e, "platform-independent-creator-channels")
            }}
          >
            super channels
          </a>
        </li>
        <li>
          <a
            href="https://smartlike.org/docs/charts-and-search"
            onClick={e => {
              gotoPage(e, "charts-and-search")
            }}
          >
            charts and search
          </a>
        </li>
      </ul>
      <style jsx global>{`
        li {
          margin-bottom: 5px;
        }
      `}</style>

      <style jsx>{`
        .dialog-frame {
          border: 2px solid ${css.borderColor};
          border-radius: 5px;
          padding: 5px;
        }
        .intext-link {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default Help
