import {
  faCommentDollar,
  faCalendarPlus,
  faTags,
  faLink,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import ClaimChannel from "../dialogs/claim-channel"
import IdentityEditor from "../identity-editor"
import { DonationAddressForm } from "../settings/donation-address-form"
import { navigate } from "@reach/router"
import { useUser } from "../../hooks/use-user"
import DirectMessageDialog from "../dialogs/direct-message-dialog"

const HowToResolve: FunctionComponent = () => {
  const { setContent } = useModal()
  const { user, openLoginPrompt } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const gotoPage = (event: any, path: string) => {
    if (event) event.preventDefault()
    navigate(path)
  }

  return (
    <div>
      <h3>How to resolve issues</h3>
      <p>
        We are sorry if you have an issue with the service.
        <ul>
          <li>
            Should you experience a problem with adding funds, please contact
            the donation recipient directly: "Add funds" | "Feedback" | "
            <span
              className="intext-link"
              onClick={() => {
                setContent(
                  <DirectMessageDialog
                    initMessage={{
                      from: user.id,
                      to: "",
                      text: "",
                      text_outbox: "",
                    }}
                    addMessageToFeed={msg => {}}
                    close={() => setContent(null)}
                  />
                )
              }}
            >
              new
            </span>
            ". The communication is end-to-end encrypted. We don't have access
            to this private data and thus cannot help resolve the issue.
          </li>
          <li>
            If you noticed a bug or have a question, please{" "}
            <a
              className="footer-link"
              href="https://github.com/smartlike-org/smartlike/issues"
              target="_blank"
            >
              report
            </a>{" "}
            or{" "}
            <a
              className="footer-link"
              href="https://github.com/smartlike-org/smartlike/discussions/categories/q-a"
              target="_blank"
            >
              ask
            </a>{" "}
            on the forum.
          </li>
          <li>
            Or{" "}
            <a className="footer-link" href="mailto:support@smartlike.org">
              contact us
            </a>{" "}
            directly.
          </li>
        </ul>
      </p>

      <p>
        <br />
      </p>
      <style jsx global>{`
        li {
          margin-bottom: 15px;
        }
      `}</style>

      <style jsx>{`
        .dialog-frame {
          border: 2px solid ${css.borderColor};
          border-radius: 5px;
          padding: 5px;
        }
        .intext-link {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default HowToResolve
